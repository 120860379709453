import { ComponentRef, ElementRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UIKitTooltipComponent } from './component/tooltip.component';
import { UIKitTooltipOrigin } from './directives/tooltip-origin.directive';
import { UIKIT_TOOLTIP_DEFAULT_DELAY } from './tooltip.defaults';
import { UIKitTooltipConfig } from './tooltip.model';

export interface UIKitTooltipEvent extends Event {
  originRef: ElementRef;
}

@Injectable({ providedIn: 'root' })
export class UIKitTooltipService {
  private tooltip: ComponentRef<UIKitTooltipComponent>;
  public createTooltipComponent<TContext>(vc: ViewContainerRef, templateRef: TemplateRef<TContext>, context: TContext) {
    const contentView = vc.createEmbeddedView(templateRef, context);
    const tooltipComp = vc.createComponent(UIKitTooltipComponent, { projectableNodes: [contentView.rootNodes as Node[]] })
    vc.detach();

    const showSub = tooltipComp.instance.visibility$
      .pipe(filter(x => x.show))
      .subscribe(() => {
        tooltipComp.instance.isOpen = true;
        this.tooltip.changeDetectorRef.detectChanges();
      });

    const hideSub = tooltipComp.instance.visibility$
      .pipe(filter(x => !x.show)).subscribe(x => {
        this.hideTooltip(tooltipComp, x.delay);
      });

    tooltipComp.onDestroy(() => {
      showSub.unsubscribe();
      hideSub.unsubscribe();
    });
    return tooltipComp;
  }

  public showTooltip(tooltip: ComponentRef<UIKitTooltipComponent>,
    originRef: ElementRef<UIKitTooltipOrigin>,
    config: UIKitTooltipConfig) {

    if (this.tooltip !== tooltip) {
      this.tooltip?.destroy();
      this.tooltip = tooltip;
    }
    this.tooltip.instance.originRef = originRef;
    this.tooltip.instance.config = config;
    this.tooltip.instance.show(0);
  }

  public hideTooltip(tooltip: ComponentRef<UIKitTooltipComponent>, delay?: number) {
    const showEmitted = tooltip.instance.visibility$.pipe(filter(x => x.show));
    timer(delay ?? tooltip.instance?.config?.detachDelay ?? UIKIT_TOOLTIP_DEFAULT_DELAY)
      .pipe(takeUntil(showEmitted)).subscribe(() => {
        tooltip.destroy();
        tooltip.changeDetectorRef.detectChanges();
      });
  }
}
