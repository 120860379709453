import { Directive, Input, TemplateRef } from '@angular/core';
import { DefaultHeaderTooltipConfig, DefaultInfocardTooltipConfig } from '../tooltip.defaults';
import { UIKitTooltipConfig } from '../tooltip.model';

@Directive({
    selector: '[uikit-tooltip]',
    exportAs: 'UIKitTooltip'
})
export class UIKitTooltipTemplate<TContext> {

    config = DefaultHeaderTooltipConfig;

    @Input('uikit-tooltip-config')
    set uikitTooltip(value: UIKitTooltipConfig | 'heading' | 'infocard' | 'infocardRight') {
        if (value === 'heading' || value == null) {
            this.config = DefaultHeaderTooltipConfig;
        } else if (value === 'infocard') {
            this.config = DefaultInfocardTooltipConfig;
        } else if(value === 'infocardRight') {
            this.config = { ...DefaultInfocardTooltipConfig, position: 'infocardRight' }
        }
        else {
            this.config = value;
        }
    }

    constructor(public templateRef: TemplateRef<TContext>) { }
}
