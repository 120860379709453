import { coerceCssPixelValue, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

// How tall the icon should be in relation to the circle
const ICON_SIZE_RATIO = 0.625;

/**
 * A info icon wrapped inside a circle, designed for use with a tooltip.
 * Note that this does not contain any logic related to the tooltip.
 * For that, UIKitTooltipOrigin must be added as an attribute
 */
@Component({
  selector: 'uikit-info-icon',
  template: `
  <span class="icon-wrapper">
    <uikit-icon [icon]="icon" [block]="true" [size]="iconSize"></uikit-icon>
  </span>`,
  styleUrls: ['info-icon.component.scss']
})
export class UIKitInfoIcon implements OnInit {
  @Input() type = 'infocard' as 'infocard' | 'heading';
  @Input() icon = 'info';

  _size: number;
  @Input()
  set size(value: NumberInput) { this._size = coerceNumberProperty(value); }
  get size(): number { return this._size; }

  @Input() backgroundColor: string;
  @Input() iconColor: string;

  private _elevation: string;
  @Input()
  get elevation(): string {
    return this._elevation;
  }
  set elevation(val: string) {
    this._elevation = coerceCssPixelValue(val);
  }

  @HostBinding('style')
  get style() {
    return {
      backgroundColor: this.backgroundColor,
      color: this.iconColor,
      fontSize: this.iconSize.toString() + 'px',
      bottom: this.elevation,
      padding: `${Math.floor(this.size * (1 - ICON_SIZE_RATIO) / 2)}px`
    };
  }

  protected get iconSize() {
    return Math.ceil(this.size * ICON_SIZE_RATIO);
  }

  @HostBinding('class.heading') headingClass = false;
  @HostBinding('class.infocard') infocardClass = false;

  ngOnInit() {
    if (this.type === 'infocard') {
      this.initInfocardSettings();
    } else if (this.type === 'heading') {
      this.initHeadingSettings();
    }
  }

  initInfocardSettings() {
    this.infocardClass = true;
    this.size = this.size || 28;
  }

  initHeadingSettings() {
    this.headingClass = true;
    this.size = this.size || 16;
    this.elevation = this.elevation || '0.5em';
  }
}
