import { UIKitTooltipConfig } from './tooltip.model';

export const UIKIT_TOOLTIP_DEFAULT_DELAY = 200;
// pointerSide refers to the side that has the pointer
// flatSide refers to a side on the opposite axis of the pointerSide
export const pointerSideOffset = 15;
export const flatSideOffset = 30;

export const minHeights = {
    'sm': 26,
    'md': 34,
    'lg': 56
};

export const DefaultInfocardTooltipConfig: UIKitTooltipConfig = {
    position: 'infocard',
    mobileAutoScrollInto: true,
    size: 'lg',
    maxWidth: '330px'
    // detachDelay isn't configured, UIKIT_TOOLTIP_DEFAULT_DELAY will be used
};


export const DefaultHeaderTooltipConfig: UIKitTooltipConfig = {
    position: 'centered',
    mobileAutoScrollInto: false,
    size: 'md',
    maxWidth: '500px'
    // detachDelay isn't configured, UIKIT_TOOLTIP_DEFAULT_DELAY will be used
};

