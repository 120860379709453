import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIKitIconModule } from '../icon';
import { UIKitInfoIcon as UIKitInfoIcon } from './info-icon/info-icon.component';
import { UIKitTooltipOrigin } from './directives/tooltip-origin.directive';
import { UIKitTooltipTemplate } from './directives/tooltip-template.directive';
import { UIKitTooltipComponent } from './component/tooltip.component';

@NgModule({
  declarations: [UIKitTooltipComponent, UIKitTooltipOrigin, UIKitTooltipTemplate, UIKitInfoIcon],
  imports: [CommonModule, PortalModule, OverlayModule, UIKitIconModule],
  exports: [UIKitTooltipOrigin, UIKitTooltipTemplate, UIKitInfoIcon]
})
export class UIKitTooltipModule { }
