import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkConnectedOverlay, ConnectedOverlayPositionChange, FlexibleConnectedPositionStrategy, Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NeedsToUnsubscribe } from 'app/shared';
import { BehaviorSubject, fromEvent, zip } from 'rxjs';
import { distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs/operators';
import { UIKitTooltipEvent } from '../tooltip.service';
import { getPositions } from './position-builder';
import * as config from '../tooltip.model';
import * as defaults from '../tooltip.defaults';

@Component({
    styleUrls: ['./tooltip.component.scss', './tooltip.typography.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <ng-template #overlay="cdkConnectedOverlay" cdkConnectedOverlay
        [cdkConnectedOverlayOpen]="isOpen"
        [cdkConnectedOverlayPositionStrategy]="positionStrategy"
        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
        (attach)="attached(overlay)"
        (positionChange)="positionChanged($event)">
      <div (mouseleave)="hide()" (mouseenter)="show()">
        <div class="uikit-tooltip-content" [class]="[sizeCssClass, positionCssClass]">
            <ng-content></ng-content>
        </div>
      </div>
  </ng-template>`
})
export class UIKitTooltipComponent extends NeedsToUnsubscribe implements OnInit, OnDestroy {
    private insightContainer = document.querySelector('.insight-container');
    private connectedOverlay: CdkConnectedOverlay;

    originRef: ElementRef;
    config = defaults.DefaultHeaderTooltipConfig;

    isOpen = false;

    positionStrategy: FlexibleConnectedPositionStrategy;
    scrollStrategy: RepositionScrollStrategy;
    sizeCssClass: string;
    positionCssClass: string;

    private _visibility = new BehaviorSubject<{ show: boolean, delay: number }>({ show: false, delay: 0 });
    visibility$ = this._visibility.asObservable();

    hide(delay?: number) {
        this._visibility.next({ show: false, delay: delay });
    }

    show(delay?: number) {
        this._visibility.next({ show: true, delay: delay });
    }
    constructor(
        private overlay: Overlay,
        private breakpointObserver: BreakpointObserver,
        private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.sizeCssClass = `uikit-tooltip-size-${this.config.size}`;
        this.positionCssClass = 'uikit-tooltip-left-down';
        this.breakpointObserver.observe(Breakpoints.HandsetPortrait).pipe(first())
            .subscribe(breakpointState => {
                const positions = getPositions(this.config.position || 'centered', breakpointState.matches);

                this.positionStrategy = this.overlay.position()
                    .flexibleConnectedTo(this.originRef)
                    .withPush(false)
                    .withFlexibleDimensions(false)
                    .withPositions(positions);

                this.scrollStrategy = this.overlay.scrollStrategies.reposition();

                if (breakpointState.matches) {
                    this.handleMobileScrollInto();
                }
            });

        if (this.insightContainer) {
            fromEvent<UIKitTooltipEvent>(this.insightContainer, 'scroll', { passive: true }).pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    const positionStrategy =
                        this.connectedOverlay.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
                    positionStrategy.reapplyLastPosition();
                });
        }
    }

    ngOnDestroy() {
        this.isOpen = false;
        this.connectedOverlay?.overlayRef?.detach();
        this.connectedOverlay?.ngOnDestroy();
        super.ngOnDestroy();
    }

    attached(overlay: CdkConnectedOverlay) {
        this.connectedOverlay = overlay;
        overlay.overlayRef.updateSize({
            maxWidth: this.config.maxWidth,
            minHeight: defaults.minHeights[this.config.size]
        });
    }

    positionChanged(change: ConnectedOverlayPositionChange) {
        const connectionPair = change.connectionPair as config.TooltipPositionPair;
        this.positionCssClass = connectionPair.tooltipClass;
        this.cd.detectChanges();
    }

    private handleMobileScrollInto() {

        const originHtmlEl = this.originRef.nativeElement as HTMLElement;
        // Emits ConnectionPositionPair when the overlayY value has changed
        const yConnectionChange$ = this.positionStrategy.positionChanges.pipe(
            map(posChange => posChange?.connectionPair),
            distinctUntilChanged());
        // Emits visibility information when visibility is set to true
        const showTooltipEmitted = this.visibility$.pipe(filter(visibility => visibility.show));
        zip(yConnectionChange$, showTooltipEmitted)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(([posChange]) => {
                // We could use originHtmlEl.scrollIntoView(), but then there won't be any margin on the bottom/top of the screen
                // This code calculates where the container should scroll to in order to get
                const yOffset = 8;
                // A DOMRect that describes the position of the element compared to the viewport
                const originRect = originHtmlEl.getBoundingClientRect();
                const containerRect = this.insightContainer.getBoundingClientRect();

                let scrollPos = this.insightContainer.scrollTop;
                if (posChange.overlayY === 'bottom') {
                    scrollPos = scrollPos + originRect.bottom - containerRect.bottom + yOffset;
                } else if (posChange.overlayY === 'top') {
                    scrollPos = scrollPos + originRect.top - containerRect.top - yOffset;
                }

                this.insightContainer.scrollTo({ top: scrollPos, behavior: 'smooth' });

            });
    }
}

