import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentRef, Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { UIKitTooltipComponent } from '../component/tooltip.component';
import { UIKitTooltipService } from '../tooltip.service';
import { UIKitTooltipTemplate } from './tooltip-template.directive';

@Directive({
  selector: '[uikit-tooltip-origin]',
  exportAs: 'UIKitTooltipOrigin'
})
export class UIKitTooltipOrigin<TContext = unknown> {

  @Input('uikit-tooltip-origin') set tooltipOrigin(tooltipRef: UIKitTooltipTemplate<TContext>) {
    this.tooltipContentTemplate = tooltipRef;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('uikit-tooltip-context') tooltipContext: TContext;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('uikit-tooltip-disabled') tooltipDisabled = false;

  public tooltipContentTemplate: UIKitTooltipTemplate<TContext>;

  private tooltipComponentRef: ComponentRef<UIKitTooltipComponent>;

  constructor(
    private originRef: ElementRef<UIKitTooltipOrigin<TContext>>,
    private tooltipService: UIKitTooltipService,
    private vc: ViewContainerRef,
    private breakpointObserver: BreakpointObserver) { }

  open() {
    if (!this.tooltipDisabled) {
      this.tooltipComponentRef =
        this.tooltipService.createTooltipComponent(this.vc, this.tooltipContentTemplate.templateRef, this.tooltipContext);
      this.tooltipService.showTooltip(this.tooltipComponentRef, this.originRef, this.tooltipContentTemplate.config);
    }
    return this.tooltipComponentRef;
  }

  close(delay?: number) {
    this.tooltipComponentRef?.instance?.hide(delay);
  }

  @HostListener('mouseenter') _mouseEnter() {
    if (!this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait)) {
      this.open();
    }
  }

  @HostListener('mouseleave') _mouseLeave() {
    if (!this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait)) {
      this.close();
    }
  }

  @HostListener('click') _mobileClick() {
    if (this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait)) {
      if (this.tooltipComponentRef?.instance?.isOpen) {
        this.close(0);
      } else {
        this.open();
      }
    }
  }
}
