import { flatSideOffset, pointerSideOffset } from '../tooltip.defaults';
import { Dir, TooltipPositionPair, TooltipPositionStrategy } from '../tooltip.model';


function buildDesktopInfocardPosition(dir: Dir): TooltipPositionPair {
    return {
        originX: dir.x === 'left' ? 'start' : 'end',
        originY: 'center',
        overlayX: dir.x === 'left' ? 'end' : 'start',
        overlayY: dir.y === 'down' ? 'top' : 'bottom',
        offsetX: pointerSideOffset * (dir.x === 'left' ? (-1) : 1),
        offsetY: flatSideOffset * (dir.y === 'down' ? (-1) : 1),
        tooltipClass: `uikit-tooltip-${dir.x}-${dir.y}`
    };
}

function buildMobileInfocardPosition(dir: Dir): TooltipPositionPair {
    return {
        originX: 'center',
        originY: dir.y === 'down' ? 'bottom' : 'top',
        overlayX: dir.x === 'left' ? 'end' : 'start',
        overlayY: dir.y === 'down' ? 'top' : 'bottom',
        offsetX: flatSideOffset * (dir.x === 'right' ? (-1) : 1),
        offsetY: pointerSideOffset * (dir.y === 'up' ? (-1) : 1),
        tooltipClass: `uikit-tooltip-${dir.y}-${dir.x}`
    };
}

export function getPositions(positionStrategy: TooltipPositionStrategy, mobile: boolean) {

    const usedPositions: TooltipPositionPair[] = [];

    if (positionStrategy === 'centered') {
        const topPosition: TooltipPositionPair = {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -pointerSideOffset,
            tooltipClass: 'uikit-tooltip-center-up'
        };

        const bottomPosition: TooltipPositionPair = {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: pointerSideOffset,
            tooltipClass: 'uikit-tooltip-center-down'
        };

        usedPositions.push(
            topPosition,
            bottomPosition
        );
    } else if (positionStrategy === 'infocard') {
        if (mobile) {
            usedPositions.push(
                buildMobileInfocardPosition({ x: 'left', y: 'down' }),
                buildMobileInfocardPosition({ x: 'left', y: 'up' }),
                buildMobileInfocardPosition({ x: 'right', y: 'down' }),
                buildMobileInfocardPosition({ x: 'right', y: 'up' })
            );
        } else {
            usedPositions.push(
                buildDesktopInfocardPosition({ x: 'left', y: 'down' }),
                buildDesktopInfocardPosition({ x: 'left', y: 'up' }),
                buildDesktopInfocardPosition({ x: 'right', y: 'down' }),
                buildDesktopInfocardPosition({ x: 'right', y: 'up' })
            );
        }
    } else if (positionStrategy === 'infocardRight') {
        if (mobile) {
            usedPositions.push(
                buildMobileInfocardPosition({ x: 'right', y: 'down' }),
                buildMobileInfocardPosition({ x: 'right', y: 'up' }),
                buildMobileInfocardPosition({ x: 'left', y: 'down' }),
                buildMobileInfocardPosition({ x: 'left', y: 'up' })
            );
        } else {
            usedPositions.push(
                buildDesktopInfocardPosition({ x: 'right', y: 'down' }),
                buildDesktopInfocardPosition({ x: 'right', y: 'up' }),
                buildDesktopInfocardPosition({ x: 'left', y: 'down' }),
                buildDesktopInfocardPosition({ x: 'left', y: 'up' })
            );
        }
    }

    return usedPositions;

}
